.a{
    height:100vh;
   
    display:flex;
    align-items: center;
}

.a-left{
    flex:1;
    display:flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;


}

.a-right{
    flex:1;
    margin-right:30px;
    padding-right:30px;
    text-align: justify;
    opacity:0;
    transform: translateY(100%);
   
    
}

.a-card.bg{
    position:absolute;
    top: 60px;
    left: 60px;
    background-color:rgb(87, 84, 84);
    


}

.a-card{
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position:relative;
    overflow:hidden;
    opacity:0;
    transform: translateX(-100%);
   
}

.a-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity:0;
    transform: translateX(-100%);
    border-radius: 30px;
  
    

}

.a-award-img{
    height:30%;
    width: 30%;
    border-radius:20px;
}

.a-title{
    opacity:1;
    font-weight:400;
    
}

.a-sub{
    margin:20px 0px;
    font-weight:400;
   
    
}

.a-desc{
 margin-bottom:15px;
 font-weight:400;

 
}

.a-award{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content:space-between;
}

.a-award-texts{
    width: 70%;
}

.a-award-title{
    font-weight:bold;
    color:green;
    margin-bottom: 5px;
    font-size: larger;
}

.a-award-title:hover{

text-decoration: underline;

}

.animate-card-img {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 1s, transform 1s;
  }
  
 
  
  .animate-text {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 4s, transform 2s;
  }
  
  


@media screen and (max-width: 480px){


    .a{
        flex-direction: column;
        text-align: center;
        margin-top:50px;

    }

    .a-left{
        width:100%;
    }

    .a-card{
        height: 30vh;
    }

    .a-card.bg, .a-award{
        display:none;
    }

    .a-right{
        padding: 20px;
        margin-right: 10px;
        padding-right:10px;
    }
    
    
}

@media (orientation:landscape) and (max-width:900px){

    .i{
        margin-bottom:200px;
    }

    .a{
        margin-bottom:100px;
    }

    .a-award{
        display:none;
    }
    
}

@media screen and (max-width:770px){

    
    .a{
        margin-bottom:100px;
    }

  
    
}




    
