.p{

width: 30%;
height: 30vh;
margin: 20px 10px;
border: 2px solid rgb(243,242,242);
border-radius: 10px 10px 0px 0px;
overflow: hidden;
cursor: pointer;

}

.p-browser{

height: 20px;
background-color: black;
display: flex;
align-items: center;
position:sticky;
z-index: 5;


}

.p-circle {

    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 3px;
    background-color: white;
}

.p-img {

    width:100%;
    position:relative;
    right:10px;
    transition: all 12s ease;

}



.p:hover .p-img{
    transform: translateY(-100%)
}

@media screen and (max-width:480px){


    .p{
        width: 40%;
        height: 20vh;
        
    }
  

}