.c{
    
    position:relative;
    margin-top:50px;
    padding-bottom:30px;

}

.c-bg{
    width:20px;
    height: 100%;
    background-color: #249b9b;
    position: absolute;

}

.c-wrapper{
    padding: 50px;
    display:flex;
    height:auto;

}

.c-left{
 flex:1;

}

.c-right{
    flex:1;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;

   
}

.c-title{
    font-size: 60px;
    width:80%;
    
}

.c-desc {
    font-weight: 300;
    font-size:19px;
   
   
    
}

.c-info-item{
    display:flex;
    align-items:center;
    margin: 50px 0px;
    font-weight:300;
    width:70%;
}

.c-icon{
    width:30px;
    height:30px;
    margin-right: 20px;
}

form{

    margin-top: 20px;

}

input{

    width:50%;
    height:50px;
    border: none;
    border-bottom: 1px solid black;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;

}

textarea{
    width: 100%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;



}

 button{
     border:none;
     padding: 15px;
     background-color: #249b9b;
     color:white;
     cursor:pointer;
     margin-right:10px;
     border-radius:10px;

 }

 button:hover{
    transform:scale(1.1)
    
 }

 

 @media screen and (max-width: 480px) {


    .c-wrapper{
        flex-direction: column;
        padding: 10px 50px 50px 50px;
        text-align: center;
        
    }

    .c-bg{
        
        height: 110%;
        
    
    }

    .c{
        height:auto;
    }
   
     

    .c-title{
        font-size:30px;
        margin-bottom:2.5rem;

    }

    .c-info-item{
        margin:20px 0px;
        width: 100%;

    }

    .c-desc{
        display:none;

    }

    form{
        margin-top: 0px;
        display:flex;
        flex-wrap:wrap;
    }

    input{
        width:35%;
        height:40px;
        margin: 10px;
        margin-left: 0;
    }

    button{
        margin-top: 1px;
    }

}   

