.pl{

padding:50px 100px;
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
/*cubic-bezier(0.42, 0.52, 0.58, 1)*/
}



.pl-texts{

 width: 65%;
 

}

.pl-title{

 font-size: 40px;
 font-weight: 500;
 margin-bottom:2rem;
}

.pl-desc{

    margin: 20px 0px;
   
}

.pl-list{

 display: flex;
 flex-wrap:wrap;
 justify-content: space-between;
 width: 100%;

}

#emoji{
    display: inline-block;
    position:relative;
    right:15px;
    transition: transform 0.3s ease;
}

#emoji:hover{
    
    transform: rotate(360deg);
}


.animate {
    transform: scale(1);
   
}
  
 
  



@media screen and (max-width:480px){

    .pl{
        padding: 10px;
        margin-top:50px;
    }
    .pl-texts{

        width: 100%;
        
       
    }

    .pl-desc{
        display:none;
    }

    

}