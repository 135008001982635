.wrapper{
   
    background-color: black;
    height:115px;
    position:absolute;
    width:100%;
    display:flex;
    justify-content:center; 
    align-items:center;
    margin-top:0px;
       

}

a{
    color:rgb(173, 171, 171);
    cursor:pointer;
    padding:10px;
    text-decoration: none;
}


a:visited{
    text-decoration: none;
  
}







