.i {
    display: flex;
    height: 100vh;


}

span {
    margin-left: 10px;
    margin-top: 15px;
}

.bandera {
    height: 18px;
    width: 30px;
    cursor: pointer;

}


.i-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.i-right {
    flex: 1;
    display: flex;
    position: relative;

}

.i-left-wrapper {
    padding: 50px;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.i-intro {

    font-size: 30px;
    font-weight: 300;

}

.i-name {
    font-size: 60px;

}

.i-title {
    height: 50px;
    overflow: hidden;

}

.i-desc {
    font-size: 1.2rem;
}



.i-scroll {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 20px;
}

.i-title-wrapper {
    height: 100%;
    animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
    25% {
        transform: translateY(-50px);
    }

    50% {
        transform: translateY(-100px);
    }

    75% {
        transform: translateY(-150px);
    }

    100% {
        transform: translateY(-200px);
    }

}

.i-title-item {
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: #59b256
}




.i-img {
    width: 85%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 110px;
    filter: grayscale(100%);
}

.i-bg {
    clip-path: polygon(100% 0%, 100% 58%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
    background: linear-gradient(-45deg, #59b256, #59b256, #23a6d5, #23a6d5);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}



@media screen and (max-width: 480px) {


    .i {
        flex-direction: column;


    }

    .i-title {
        margin: 30px;
    }

    .i-left-wrapper {
        padding: 10px;
        align-items: center;
        text-align: center;
        height: 100%;
        justify-content: center;
    }

    .i-desc {
        display: none;
    }

    .i-img {
        left: 56px;
        width: 85%;
        height: 100%;
        object-fit: cover;

    }

}



@media screen and (min-width: 768px) {

    .i {
        margin-bottom: 50px;
    }

    .i-img {
        width: 100%;
        height: 50%;
        left: 30px;
        top: 410px;
    }

    .i-bg {
        height: 91%;
    }



    .i-name {
        position: relative;
        bottom: 60px;
    }

}





@media (orientation:landscape) and (max-width:900px) {

    .i {
        margin-bottom: 100px;
    }

    .i-desc {
        font-size: 15px;
        margin-top: 20px;
    }

    .i-img {
        left: 50px;
    }


    .i-bg {
        height: 92.5%;
    }


}

@media (orientation:landscape) and (max-width:1900px) {



    .i-img {
        left: 50px;
        top: 10px;
        width: 95%;
        height: 90%;
    }




}



@media only screen and (min-width: 1440px) {

    .i-img {
        width: 85%;
        height: 100%;
        left: 110px;
        top: 0;
    }

    .i-bg {
        height: 100%;
    }


}