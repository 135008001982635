.s {
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    height:auto;
  
}

.s-container{
    opacity:0;
    transform: translateY(100%);
    display: flex;
    flex-direction: column;
}

.s-img{
    width:25vw;
    object-fit:cover;
    align-self:center;
    z-index:5;
}

.s-title{
    font-size: 40px;
    font-weight: 500;
}

.s-subtitle{
    font-weight:500;
    margin-bottom:2rem;
}   

.s-flex {
    display: flex;
    flex-direction: row;
    margin-top:3rem;
    
}

.s-right {
    display: flex;
    flex: 1;
    flex-direction:column;
    align-items: center;
}

.s-left {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}


.s-title {
   align-self: center;
    font-size: 40px;
    font-weight: 500;
    margin-bottom:1rem;
}

.s-list{
    list-style-type: none;
}

.s-p{
    margin-top:1.5rem;
    margin-bottom:1.5rem;
}

li{
    margin-bottom:0.5rem;
}

.animate{
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 2s, transform 1.2s;

}


@media screen and (max-width: 480px) {

    .s {
        padding: 0px;
        width:90%;
        margin:auto;
     
    }

    .s-flex {
      
        flex-direction: column;
        margin-left:2.5rem;
        row-gap:3.5rem;
        
    }

    .s-right {
      
       
        flex-direction:column;
        align-items: start;
    }
    
    .s-left {
        display: flex;
        align-items: start;
    }

    .s-title {
        text-align: center;
         margin-bottom:0rem;
     }
    
}
